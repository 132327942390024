import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import AppChrome from '../components/AppChrome';
import ContentWrapper from '../components/reusables/ContentWrapper';
import SearchForm from '../components/SearchForm';
import TopNav from '../components/TopNav';

const EXCERPT_LENGTH = 200;

function getExcerpt(text, i, lcQuery) {
  const updatedText = `${text.slice(0, i)}<b>${text.slice(
    i,
    i + lcQuery.length
  )}</b>${text.slice(i + lcQuery.length)}`.replaceAll(/\n\n/g, ' - ');

  const start = Math.max(0, i - EXCERPT_LENGTH);
  const end = start + EXCERPT_LENGTH * 1.5;
  const cutText = updatedText.slice(start, end);

  const cutoffPattern = /[.,?!\n]/g;

  let match;
  const goodPositions = [];
  while ((match = cutoffPattern.exec(cutText)) !== null) {
    if (match) {
      goodPositions.push(match.index);
    }
  }

  const first = goodPositions[0];
  const last =
    goodPositions.length > 1
      ? goodPositions[goodPositions.length - 1]
      : undefined;

  const goodStart = first && first < EXCERPT_LENGTH * 0.25 ? first + 1 : 0;
  const goodEnd =
    last && last > cutText.length - EXCERPT_LENGTH * 0.25
      ? last + 1
      : cutText.length - 1;
  return `[...]${cutText.slice(goodStart, goodEnd)}[...]`;
}

function findItems(allItems, query) {
  if (query === '') {
    return [];
  }
  const lcQuery = query.toLowerCase();
  const result = [];
  for (const item of allItems) {
    const i = item.text.toLowerCase().indexOf(lcQuery);
    if (i === -1) {
      continue;
    }
    const clone = { ...item };
    clone.excerpt = getExcerpt(clone.text, i, lcQuery);
    result.push(clone);
  }
  return result;
}

const Item = styled(Link)({
  color: 'inherit',
  textDecoration: 'none',
  marginBottom: 50,
  display: 'block',
  b: {
    backgroundColor: '#ffc8ef',
  },
});

export default function SearchTemplate({ pageContext }) {
  const [items, setItems] = useState();
  const [q, setQ] = useState();
  useEffect(() => {
    if (typeof q === 'undefined') {
      return;
    }
    setItems(findItems(pageContext.siteSearchIndex, q));
  }, [pageContext, q]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setQ(urlParams.get('q'));
  }, []);

  const title = `Search results for ${q}`;
  return (
    <AppChrome
      title={title}
      og={{
        title,
        type: 'article',
      }}
    >
      <TopNav />
      {items && (
        <ContentWrapper css={{ maxWidth: 700, margin: '0 auto' }}>
          <div
            css={{
              marginTop: 30,
              marginBottom: 50,
              textAlign: 'center',
            }}
          >
            <SearchForm initialValue={q} large />
          </div>
          {items && (
            <div>
              {items.length === 0 ? (
                <div>{q !== '' && <p>Inga sökträffar</p>}</div>
              ) : (
                <div>
                  <h2>Sökträffar</h2>
                  {items.map(item => {
                    return (
                      <Item to={item.path}>
                        <h3>{item.title}</h3>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.excerpt }}
                        />
                      </Item>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </ContentWrapper>
      )}
    </AppChrome>
  );
}
